

































































































































































































































import { Component, Prop } from 'vue-property-decorator'
import { formatTimestamp } from '@/utils/utils'
import ScaDialog from './ScaDialog.vue'
import VueBase from '@/VueBase'
import { ScaListObj } from './types'
import ScrollToTop from '@/components/scrollToTop/scrollToTop.vue'

@Component({ name: 'ScaList', components: { ScrollToTop, ScaDialog } })
export default class ScaList extends VueBase {
  @Prop() version: string | number | undefined
  @Prop() projectId: any
  private sortSelect(flag: any) {
    this.searchObj.sort = flag
    this.newSelectData()
  }
  total = 0

  private dialogInfo = {}

  private debounceMyScroll: any
  private page = 1
  private pageSize = 10
  private dataEnd = false
  private tableData: Array<ScaListObj> = []
  private searchOptionsObj: any = {
    language: [],
    level: [],
    license: [],
    orderOptions: [
      {
        label: this.$t('views.scaList.orderOptions.level'),
        value: 'level',
      },
      {
        label: '漏洞数量',
        value: 'vul_count',
      },
    ],
  }

  private levelColor(level: any) {
    switch (level) {
      case 1:
        return { label: '高', color: '#E56363', bg: 'rgba(229, 99, 99, 0.1)' }
      case 2:
        return { label: '中', color: '#F49E0B', bg: 'rgba(244, 158, 11, 0.1)' }
      case 3:
        return { label: '低', color: '#2F90EA', bg: 'rgba(47, 144, 234, 0.1)' }
      case 0:
        return { label: '无', color: '#ABB2C0', bg: 'rgba(172, 180, 196, 0.1)' }
    }
  }

  private searchObj: any = {
    language: [],
    level: [],
    keyword: '',
    license: [],
    project_id: undefined,
    order: '',
    sort: null,
  }

  created() {
    if (this.projectId) {
      this.searchObj.project_id = this.projectId
    }
  }

  private reset() {
    this.searchObj.language = []
    this.searchObj.level = []
    this.searchObj.order = ''
    this.searchObj.sort = null
    this.searchObj.license = []
    this.searchObj.keyword = ''
    if (this.$route.name !== 'projectDetail/:pid') {
      this.searchObj.project_id = ''
    }
    this.newSelectData()
  }

  private kw = ''
  private async querySearchAsync(queryString: string, cb: any) {
    console.log(queryString)
    const res = await this.services.setting.searchProject({ name: queryString })
    if (res.status === 201) {
      const data = res.data.map((item: any) => {
        return {
          value: item.name,
          id: item.id,
        }
      })
      cb(data)
    }
  }

  private languageChange(val: string, stop: boolean) {
    if (stop) {
      return
    }
    this.searchObj.language = val
    this.newSelectData()
  }

  private levelChange(val: string, stop: boolean) {
    if (stop) {
      return
    }
    this.searchObj.level = val
    this.newSelectData()
  }

  private newSelectData() {
    this.page = 1
    this.tableData = []
    this.getTableData()
  }

  mounted() {
    this.getTableData()
    this.scaSummary()
  }

  handleSizeChange(val: number) {
    this.pageSize = val
    this.getTableData()
  }
  handleCurrentChange(val: number) {
    this.page = val
    this.getTableData()
  }
  formatTimestamp(val: string) {
    return formatTimestamp(val)
  }
  public async getTableData(flag?: undefined | boolean) {
    if (flag) {
      this.page = 1
    }
    let sort = 'asc'
    if (!this.searchObj.sort) {
      sort = 'desc'
    }
    const params: any = {
      page: this.page,
      page_size: this.pageSize,
      language_ids: this.searchObj.language,
      level_ids: this.searchObj.level,
      license_ids: this.searchObj.license,
      order: sort,
      project_id: Number(this.projectId),
      project_version_id: this.version,
    }
    if (this.searchObj.keyword) {
      params.keyword = this.searchObj.keyword
    }
    if (this.searchObj.order) {
      params.order_field = this.searchObj.order
    }
    if (!this.projectId) {
      delete params.project_id
    }
    this.loadingStart()
    const { status, data, msg, page } = await this.services.sca.scaList(params)
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.tableData = data
    this.total = page.alltotal
  }

  public async scaSummary() {
    const params: any = {
      keyword: this.searchObj.keyword,
    }
    if (this.projectId || this.version) {
      params.project_id = this.projectId
      params.version_id = this.version
    }
    this.loadingStart()
    console.log('params', params)
    const { status, data, msg } = await this.services.sca.scaSummary(params)
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.searchOptionsObj.language = data.language
    this.searchOptionsObj.level = data.level
    this.searchOptionsObj.license = data.license
  }

  private dialogFlag = false
  private goDetail(row: any) {
    // this.dialogInfo = row
    // this.dialogFlag = true
    console.log(row)
    if (this.projectId) {
      this.$router.push({
        path: `/sca/scalistDetail/1/${row.id}`,
        query: {
          projectId: this.projectId,
          package_name: row.package_name,
          package_version: row.version,
          language_id: row.language_id,
        },
      })
      return
    }
    this.$router.push({
      path: `/sca/scalistDetail/1/${row.id}`,
      query: {
        package_name: row.package_name,
        package_version: row.version,
        language_id: row.language_id,
      },
    })
  }
  projectNameSplit(name: string, total: number) {
    if (name.length > total) {
      return name.substring(0, total)
    }
    return name
  }
}
