




import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ name: 'scrollToTop' })
export default class ScrollToTop extends Vue {
  private scrollToTop(){
    // document.documentElement.scrollTop = 0
    //
    // document.body.scrollIntoView()
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth'
    })
  }
}

